/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

.modal-header-import {
  background-color: #3071a9;
  border: 2px solid #3071a9;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.modal-header-import .close {
  color: #cfcfcf;
  opacity: 1;
}

.modal-header-import .modal-title {
  color: white;
  font-size: 20px;
  font-weight: 300;
}

.modal-body-import .note-name-input {
  margin-left: 7px;
  margin-right: 7px;
}

.modal-body-import label {
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 10px;
  margin-top: 15px;
}

.modal-body-import {
  min-height: 420px;
  overflow: hidden;
}

.modal-body-import .import-btn-image-group {
  margin-top: 4px;
}

.modal-body-import .import-btn-image {
  display: inline-block;
  margin-left: 8px;
}

.modal-body-import .import-btn-image a {
  background: #fff;
  border: 1px solid #e6e6e6;
  /*border-radius: 20px;*/
  border-radius: 20%;
  color: #7c828e;
  cursor: pointer;
  display: block;
  float: left;
  font-size: 98px;
  text-align: center;
  text-decoration: none;
  height: 240px;
  padding-top: 60px;
  margin: 0 10px 0px 10px;
  width: 250px;
}

.modal-body-import .import-btn-image a:hover {
  background: #eee;
}

.modal-body-import .modal-body-import-desc {
  font-size: 15px;
  font-weight: 400;
  margin-top: 30px;
  color: black;
  font-family: 'Roboto', sans-serif;
}

.modal-footer-import {
  min-height: 65px;
}
